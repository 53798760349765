
import { Component, Vue } from 'vue-property-decorator'
import { WaterMeterDetail } from '../../types/waterMeter.d'
import echarts from 'echarts'

@Component({
  name: 'History'
})
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private info = {
    total: '',
    avg: '',
    max: '',
    min: ''
  }

  private type = 'chart'
  private deviceNumber = ''
  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 365 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return time.getTime() >= (Date.now() - 24 * 3600 * 1000) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() >= (Date.now() - 24 * 3600 * 1000)
      }
    }
  }

  private loading = false
  private dataList: Array<WaterMeterDetail> = []
  page = 1
  size = 10
  total = 0

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  private echart: any

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.onSearch()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  avgDeviation (enterNum: string, avg: string) {
    const num = enterNum ? avg ? (Math.round(Number(enterNum) * 100) - Math.round(Number(avg) * 100)) / 100 : enterNum : '--'
    const value = num === '--' || num === 0 ? '<span>--</span>' : num > 0 ? `<span style="color: #FF603B">+${num}</span>` : `<span style="color: #52C41A">${num}</span>`
    return value
  }

  initTime () {
    const date = new Date()
    const date1 = new Date(date.getTime() - 24 * 60 * 60 * 1000)
    const date2 = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)
    const val = date1.getFullYear() + '-' + this.addZero(date1.getMonth() + 1) + '-' + this.addZero(date1.getDate())
    const val2 = date2.getFullYear() + '-' + this.addZero(date2.getMonth() + 1) + '-' + this.addZero(date2.getDate())
    this.searchInfo.dateRange = [val2, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  changeType (type: string) {
    this.type = type
    this.onSearch()
  }

  getStatistic (): void {
    this.$axios.get(this.$apis.waterMeter.selectWaterStatistic, {
      deviceId: this.deviceId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    }).then((res: { deviceNumber: string; total: string; avg: string; max: string; min: string }) => {
      this.deviceNumber = res.deviceNumber
      this.info = {
        total: res.total,
        avg: res.avg,
        max: res.max,
        min: res.min
      }
    })
  }

  getDataChart (): void {
    this.$axios.get(this.$apis.waterMeter.selectWaterMap, {
      deviceId: this.deviceId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    }).then((res: { wmDayLaccDataTimeList: Array<string>; wmDayLaccDataList: Array<string>; wmDayLaccAvgDataList: Array<string> }) => {
      this.draw(res.wmDayLaccDataTimeList || [], res.wmDayLaccDataList || [], res.wmDayLaccAvgDataList || [])
    })
  }

  getData (): void {
    this.loading = true
    this.$axios.get(this.$apis.waterMeter.selectWaterPage, {
      deviceId: this.deviceId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1],
      page: this.page,
      size: this.size
    }).then((res: WaterMeterDetail) => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.getStatistic()
      if (this.type === 'chart') {
        this.getDataChart()
      } else {
        this.getData()
      }
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  draw (xData: Array<string>, yData1: Array<string>, yData2: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        legend: {
          top: 30,
          left: 0,
          show: true,
          type: 'plain',
          itemGap: 25,
          itemWidth: 18,
          icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z'
        },
        title: {
          text: '耗水分析（m³）',
          textStyle: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000000'
          }
        },
        backgroundColor: 'transparent',
        color: ['#2C8EFF', '#1AD1B0'],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: '耗水量',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: 'rgba(44, 142, 255, 0.2)',
            shadowOffsetY: 20
          },
          data: yData1
        }, {
          name: '日均耗水量',
          type: 'line',
          symbolSize: 0,
          smooth: true,
          lineStyle: {
            width: 2,
            type: 'dashed'
          },
          data: yData2
        }]
      }
      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
